@keyframes custom-pulse-dark {
  0%,
  100% {
    background-color: #191919; /* Your initial color */
  }
  50% {
    background-color: #404040; /* Your new color */
  }
}
@keyframes custom-pulse-light {
  0%,
  100% {
    background-color: #ffffff; /* Your initial color */
  }
  50% {
    background-color: #eaeaea; /* Your new color */
  }
}

/* Apply the custom animation class to your elements */
.custom-pulse-dark {
  animation: custom-pulse-dark 1.2s infinite;
}
.custom-pulse-light {
  animation: custom-pulse-light 1.2s infinite;
}

@keyframes custom-pulses {
  50% {
    background-color: #191919; /* Your initial color */
  }
  0%,
  100% {
    background-color: #404040; /* Your new color */
  }
}
@keyframes custom-pulses-light {
  50% {
    background-color: #ffffff; /* Your initial color */
  }
  0%,
  100% {
    background-color: #eaeaea; /* Your new color */
  }
}

.custom-pulses {
  animation: custom-pulses 1.2s infinite;
}
.custom-pulses-light {
  animation: custom-pulses-light 1.2s infinite;
}

@keyframes custom-users-dark {
  50% {
    background-color: #191919; /* Your initial color */
  }
  0%,
  100% {
    background-color: #404040; /* Your new color */
  }
}

.custom-users-dark {
  animation: custom-users-dark 1.2s infinite;
}
@keyframes custom-users-light {
  50% {
    background-color: #fafafa; /* Your initial color */
  }
  0%,
  100% {
    background-color: #ffffff; /* Your new color */
  }
}

.custom-users-light {
  animation: custom-users-light 1.2s infinite;
}

.display-cost-card {
  color: rgb(112, 83, 255);
  position: relative;
  /* rgb(159, 139, 255) */
}

.display-cost-card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('../../../../../../public/images/displaycostcardbg.svg');
  /* background-position-x: -4px; */
  /* background-position-y: 0px; */
  z-index: 50;
  background-repeat: no-repeat;
  background-size: cover;
  opacity: 0.2;
}
.gradient {
  background-size: '2400% 2400%';
  background-position: '400px 400px';
  background-repeat: 'no-repeat';
}
@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.rotate-animation {
  animation: rotate 2s linear infinite;
}

.nft-logo-bg {
  background: url('../../../../../../public/images/nftchainlogo.svg');
  background-position-x: center;
  background-position-y: center;
  background-size: inherit;
  background-repeat: no-repeat;
}

.pendingloader {
  position: absolute;
  top: 1.2vh;
  left: 1.2vh;
  z-index: 1000; /* High z-index to ensure it's on top */
  width: 2.4vh;
  height: 2.4vh;
  border: 0.3vh solid #ffcc00;
  border-radius: 50%;
  border-top: 1.2vh solid transparent;
  animation: spin 2s linear infinite;
  transform: translate(-50%, -50%);
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.nft-page-bg-gradient{
 background-image: url('../../../../../../public/images/Rectangle3785.png');
 background-size: 446px 400px;
 background-position: center 10px;
 background-repeat: no-repeat ;
}

.nft-description {
  padding: 0 16px;
}

.description {
  max-height: 4.5em; /* Approximate height for two lines of text */
  overflow: hidden;
  transition: max-height 0.3s ease;
}

.description.show-full {
  max-height: none; /* Allows the full description to show */
}

.attributes-section {
  transition: transform 0.3s ease;
}

.attributes-section.shift-down {
  transform: translateY(90px); /* Adjust as necessary */
}

.attributes-section.shift-up {
  transform: translateY(-100px); /* Adjust as necessary */
}

.nft-background-blur {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  /* opacity: 0.08; */
     filter: blur(50px);
  -webkit-filter: blur(50px);    
 

  /* overflow: hidden; This will ensure the blur doesn't bleed outside the border-radius */
 /* Adjust the px value as needed for the desired blur amount */
}

/* src/styles.css */
@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@layer base {
  body {
    @apply m-0 antialiased bg-white dark:bg-[#101010] min-h-screen flex items-center justify-center overflow-hidden;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
      "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
      "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}

.bg {
  background-image: url("../public/images/bg.jpg") !important;
}

.nft-footer-bg-gradient {
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.56) 30%,
    #fff 51.46%
  );
}
.nft-footer-bg-gradient-dark {
  background: linear-gradient(
    180deg,
    rgba(16, 16, 16, 0) 0%,
    rgba(16, 16, 16, 0.56) 30%,
    #101010 51.46%
  );
}

/* Standard */
::-webkit-scrollbar {
  width: 6px;
}

/* WebKit (older versions) */
::-webkit-scrollbar {
  width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: none;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #404040;
  border-radius: 6px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
  border-radius: 6px;
}
.dot-flashing {
  position: relative;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #2d2d2d;
  color: #2d2d2d;
  animation: dot-flashing 1s infinite linear alternate;
  animation-delay: 0.5s;
}
.dot-flashing::before,
.dot-flashing::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
}
.dot-flashing::before {
  left: -15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #2d2d2d;
  color: #2d2d2d;
  animation: dot-flashing 1s infinite alternate;
  animation-delay: 0s;
}
.dot-flashing::after {
  left: 15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #2d2d2d;
  color: #2d2d2d;
  animation: dot-flashing 1s infinite alternate;
  animation-delay: 1s;
}

@keyframes dot-flashing {
  0% {
    background-color: #2d2d2d;
  }
  50%,
  100% {
    background-color: rgba(152, 128, 255, 0.2);
  }
}
@keyframes moveToLeft {
  from {
    transform: translateX(0vw); /* Start off-screen to the right */
  }
  to {
    transform: translateX(
      -80%
    ); /* Move to the left until it's completely off-screen */
  }
}
.moving-div {
  animation: moveToLeft 100s linear infinite; /* Adjust time as needed */
  position: absolute; /* Or fixed, depending on your layout needs */
  top: 0; /* Adjust based on where you want your div to start */
  left: 0;
  /* Ensure your div has a width and height set */
}
